import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'throttle-debounce';
import ReactMarkdown from 'markdown-to-jsx';

import FormElement from '@shipae/components-sandbox/component/form-element';
import Input from '@shipae/components-sandbox/component/input';
import Select, { ValueType }
  from '@shipae/components-sandbox/component/select';
import SpinnerIcon from '@shipae/components-sandbox/component/icons/spinner';
import { sd300 } from '@shipae/components-sandbox/component/colors';
import PrimaryButton from
  '@shipae/components-sandbox/component/buttons/primary';

import { JobListing } from 'infrastructure/gatsby/types/job-listing';
import { loadComponent } from 'application/core/control/actions';
import { buildFilteredJobsList } from 'application/lazy/job-listing/service';
import {
  getDepartments,
  getFilter,
  getFilteredDepartments,
  getFilteredLocations,
  getJobList,
  getJobListingModuleLoaded,
  getLocations,
  getSearchQuery,
  isJobsLoading,
} from 'application/lazy/job-listing/selectors';
import {
  fetchJobsData,
  setFilterDepartments,
  setFilterLocations,
  setSearchQuery,
} from 'application/lazy/job-listing/actions';

import gloabalReactMdOptions from '../shared/react-makdown-options';
import SmartLink from '../smart-link';
import { buildOptions } from './utils';
import {
  Banner,
  CallToAction,
  Container,
  Department,
  EmptyData,
  EmptyDataMessage,
  EmptyDataTitle,
  FirstCell,
  FlexChild,
  Form,
  FormElementTheme,
  Heading,
  InputTheme,
  ItemContainer,
  JobItem,
  JobList,
  LoadingContainer,
  Location,
  Main,
  NoDataIcon,
  NoDataImage,
  ProcessingText,
  SecondCell,
  Section,
  SelectTheme,
  ThirdCell,
  Title,
} from './styles';

const JobListingSection: React.FC<JobListing> = ({
  id,
  sectionTitle,
  sectionId,
  searchPlaceholder,
  locationPlaceholder,
  departmentPlaceholder,
  loadingMessage,
  emptyDataTitle,
  emptyDataMessage,
  emptyDataImage,
  callToAction,
  bannerContent,
}) => {
  const dispatch = useDispatch();

  const isModuleLoaded = useSelector(getJobListingModuleLoaded);
  const isLoading = useSelector(isJobsLoading);
  const jobList = useSelector(getJobList);
  const locations = useSelector(getLocations);
  const departments = useSelector(getDepartments);
  const filter = useSelector(getFilter);
  const searchQuery = useSelector(getSearchQuery);
  const selectedLocations = useSelector(getFilteredLocations);
  const selectedDepartments = useSelector(getFilteredDepartments);

  const filteredJobs = useMemo(() => {
    const emptyFilter = !filter.search
      && filter.locations?.length === 0
      && filter.departments?.length === 0;

    const filteredJobsList = buildFilteredJobsList({ jobs: jobList, filter });
    // The logic below for cutting the first 5 job items in the case
    // no filters are selected was get and reproduced from the code
    // snippet used in integrating the Workable API on Agility.com.
    const cuttedEmptyFilterList = filteredJobsList.slice(0, 5);

    return emptyFilter ? cuttedEmptyFilterList : filteredJobsList;
  }, [jobList, filter]);

  const locationsOptions = useMemo(
    () => buildOptions(locations),
    [locations],
  );
  const departmentsOptions = useMemo(
    () => buildOptions(departments),
    [departments],
  );
  const selectedLocationsOptions = buildOptions(selectedLocations);
  const selectedDepartmentsOptions = buildOptions(selectedDepartments);

  useEffect(() => {
    dispatch(loadComponent('job-listing'));
  }, []);

  useEffect(() => {
    if (isModuleLoaded) {
      dispatch(fetchJobsData());
    }
  }, [isModuleLoaded]);

  const onSearchChange = (value: string): void => {
    dispatch(setSearchQuery(value));
  };

  const onSelectLocations = debounce(300, (value?: ValueType) => {
    if (Array.isArray(value)) {
      dispatch(setFilterLocations(value.map((option) => option.value)));
    }
  });

  const onSelectDepartments = debounce(300, (value?: ValueType) => {
    if (Array.isArray(value)) {
      dispatch(setFilterDepartments(value.map((option) => option.value)));
    }
  });

  return (
    <Section id={sectionId} data-ga={`JobListing-${ id }`}>
      <Container>
        <Heading>{sectionTitle}</Heading>
        <Main>
          <Form>
            <FlexChild>
              <FormElement
                theme={FormElementTheme}
                label={searchPlaceholder}
                hasValue={!!searchQuery}
              >
                <Input
                  theme={InputTheme}
                  type="text"
                  debounceTimeout={500}
                  value={searchQuery}
                  onChange={onSearchChange}
                />
              </FormElement>
            </FlexChild>
            <FlexChild>
              <FormElement
                theme={FormElementTheme}
                label={departmentPlaceholder}
                hasValue={!!selectedDepartmentsOptions[0]?.value}
              >
                <Select
                  theme={SelectTheme}
                  error={false}
                  options={departmentsOptions}
                  value={selectedDepartmentsOptions}
                  multiple
                  onChange={onSelectDepartments}
                />
              </FormElement>
            </FlexChild>
            <FlexChild>
              <FormElement
                theme={FormElementTheme}
                label={locationPlaceholder}
                hasValue={!!selectedLocationsOptions[0]?.value}
              >
                <Select
                  theme={SelectTheme}
                  error={false}
                  options={locationsOptions}
                  value={selectedLocationsOptions}
                  multiple
                  onChange={onSelectLocations}
                />
              </FormElement>
            </FlexChild>
          </Form>
          {!isLoading ? (
            <JobList>
              {filteredJobs?.length > 0 ? (
                filteredJobs?.map((jobItemInfo) => {
                  const {
                    url, title, department, location,
                  } = jobItemInfo;
                  const { locationStr } = location || {};

                  return (
                    !!url && (
                      <JobItem href={url} rel="noreferrer" key={jobItemInfo.id}>
                        <ItemContainer>
                          <FirstCell>
                            <Title>{title}</Title>
                          </FirstCell>
                          <SecondCell>
                            <Department>{department}</Department>
                          </SecondCell>
                          <ThirdCell>
                            <Location>{locationStr}</Location>
                          </ThirdCell>
                        </ItemContainer>
                      </JobItem>
                    )
                  );
                })
              ) : (
                <EmptyData>
                  {!!emptyDataImage?.localFile?.childImageSharp?.fluid && (
                    <NoDataImage
                      fluid={emptyDataImage?.localFile?.childImageSharp?.fluid}
                      alt={emptyDataImage?.alternativeText || ''}
                      objectFit="cover"
                      objectPosition="center top"
                    />
                  )}
                  {!emptyDataImage?.localFile?.childImageSharp?.fluid
                    && !!emptyDataImage?.localFile?.publicURL && (
                      <NoDataIcon
                        src={emptyDataImage?.localFile?.publicURL}
                        alt={emptyDataImage?.alternativeText || ''}
                        loading="lazy"
                      />
                  )}
                  {!!emptyDataTitle && (
                    <EmptyDataTitle>{emptyDataTitle}</EmptyDataTitle>
                  )}
                  {!!emptyDataMessage && (
                    <EmptyDataMessage>{emptyDataMessage}</EmptyDataMessage>
                  )}
                </EmptyData>
              )}
            </JobList>
          ) : (
            <LoadingContainer>
              <SpinnerIcon width={5} color={sd300()} />
              {!!loadingMessage && (
                <ProcessingText>{loadingMessage}</ProcessingText>
              )}
            </LoadingContainer>
          )}
        </Main>

        <CallToAction>
          {callToAction?.map((callToActionItem) => {
            const { link, label } = callToActionItem;

            return (
              <PrimaryButton
                key={callToActionItem.id}
                href={link}
                linkComponent={SmartLink}
              >
                {label}
              </PrimaryButton>
            );
          })}
        </CallToAction>
        {!!bannerContent && (
          <Banner>
            <ReactMarkdown options={gloabalReactMdOptions}>
              {bannerContent || ''}
            </ReactMarkdown>
          </Banner>
        )}
      </Container>
    </Section>
  );
};

export default JobListingSection;
