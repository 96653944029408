import styled from 'styled-components';
import GatsbyImage from 'gatsby-image/withIEPolyfill';
import { FluidObject } from 'gatsby-image';

import FormElementSDTheme from '@shipae/components-sandbox/component/form-element/theme/shipa-delivery'; // eslint-disable-line max-len
import InputSDTheme
  from '@shipae/components-sandbox/component/input/theme/shipa-delivery';
import SelectSDTheme
  from '@shipae/components-sandbox/component/select/theme/shipa-delivery';
import {
  black,
  blackGrey, cinnabar,
  errorText, flamingo,
  g100,
  g200,
  sd300,
} from '@shipae/components-sandbox/component/colors';

import { media } from 'views/theme';

import SmartLink from '../smart-link';
import { Heading as SharedHeading } from '../shared/heading';

export const Section = styled.section`
  padding: 10rem calc((100% - var(--container-width)) / 2);
`;

export const Container = styled.div``;

export const Heading = styled(SharedHeading)`
  margin-bottom: 3rem;
  text-align: start;
`;

export const Main = styled.div``;

export const Form = styled.form`
  display: flex;
  gap: 1rem;

  & > div:nth-child(1) {
    flex: 5;

    ${ media.max(767) } {
      flex: unset;
      min-width: 100%;
    }
  }

  ${ media.max(767) } {
    flex-wrap: wrap;
    row-gap: unset;
  }

  ${ media.sm } {
    flex-direction: column;
    gap: unset;
  }
`;
export const FlexChild = styled.div`
  flex: 2.5;
`;
export const FormElementTheme = {
  ...FormElementSDTheme,
  Main: styled(FormElementSDTheme.Main)`
      ${ media.max(767) } {
          margin-bottom: 16px;
      }
  `,
  Label: styled(FormElementSDTheme.Label)`
    letter-spacing: 0.02rem;
  `,
};
export const InputTheme = {
  ...InputSDTheme,
  Input: styled(InputSDTheme.Input)`
    color: ${ blackGrey() };
    &:focus {
      border-bottom: 1px solid ${ (pr) => (pr.error ? errorText() : sd300()) };
    }
  `,
};
export const SelectTheme = {
  ...SelectSDTheme,
  MultiInput: {
    ...SelectSDTheme.MultiInput,
    OptionsContainer: styled(SelectSDTheme.MultiInput.OptionsContainer)`
      grid-template-columns: 1fr 5rem;
    `,
    MoreNumber: styled(SelectSDTheme.MultiInput.MoreNumber)`
      width: 2rem;
      height: 2rem;
      line-height: 2rem;
      font-size: 1.2rem;
    `,
  },
  Item: {
    ...SelectSDTheme.Item,
    Label: styled(SelectSDTheme.Item.Label)`
      font-size: 1.6rem;
    `,
  },
};

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 6rem 0;

  ${ media.sm } {
    margin: 6rem 0;
  }
`;
export const ProcessingText = styled.p`
  margin: 0;
  padding: 2rem 0 0 0;
`;

export const EmptyData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 4rem 0;
  gap: 0.5rem;
`;
export const NoDataImage = styled(GatsbyImage)<{
  fluid: FluidObject | FluidObject[];
}>`
  width: 15rem;
  height: 15rem;
`;
export const NoDataIcon = styled.img`
  width: 10rem;
  height: 10rem;
`;
export const EmptyDataTitle = styled.h6`
  font-size: 2rem;
  font-weight: 300;
`;
export const EmptyDataMessage = styled.p`
  font-size: 2rem;
  font-weight: 200;
`;

export const JobList = styled.div`
  margin: 0 0 3rem 0;
`;
export const JobItem = styled(SmartLink)`
  text-decoration: none;
  background-color: transparent;
  color: ${ flamingo() };

  &:hover, &:active {
      color: ${ cinnabar() };
  }
`;
export const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  padding: 2rem 1rem;
  border-bottom: 1px solid ${ g200() };
  gap: 2rem;

  &:hover {
    background: ${ g100() };
  }

  ${ media.sm } {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
`;
export const FirstCell = styled.div`
    flex: 5;
`;
export const SecondCell = styled.div`
    flex: 2;
`;
export const ThirdCell = styled.div`
    flex: 3;
`;
export const Title = styled.h6`
  font-size: 2.4rem;
  font-weight: 250;
  line-height: 150%;
`;
export const Department = styled.p`
  font-size: 2rem;
  font-weight: 200;
  color: ${ black() };
`;
export const Location = styled.p`
  font-size: 2rem;
  font-weight: 200;
  color: ${ black() };
`;

export const CallToAction = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 3rem;
`;

export const Banner = styled.div`
  background: ${ g100() };
  margin-top: 3rem;
  padding: 2rem;
  border-radius: 1rem;

  & a {
    color: ${ flamingo() };

    &:hover, &:active {
        color: ${ cinnabar() };
    }
  }
`;
